export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBLm8yHcPJaBksXjL1GPXw9KVlgs99q4z4",
    authDomain: "sa-safemebizacab.firebaseapp.com",
    databaseURL: "https://sa-safemebizacab-default-rtdb.firebaseio.com",
    projectId: "sa-safemebizacab",
    storageBucket: "sa-safemebizacab.appspot.com",
    messagingSenderId: "21455221776",
    appId: "1:21455221776:web:110c755dc139a01ac8c695",
    measurementId: "G-CXD07JHK9E"
  },
  adminEmail: 'support@sa-safeme.com', // you need to create new account in firebase auth manually,
  defaultCarSettings: {
    prices: {
      default: {
        vehicles: {
          suv: {
            base_fare: "1",
            base_km: "10",
            commission_type: "percentage",
            commission_value: "5",
            icon: "assets/img/suv.svg",
            map_icon: "assets/img/map-suv.png",
            name: "SUV",
            per_km: "1",
            seats: "4",
            tax: "5",
            type: "suv",
          }
        }
      }
    }
  },
  defaultSettings: {
    canDriverSignup: true,
    canRiderSignup: true,
    currency: "R",
    driverInitialRating: "5",
    driverPrivacyURL: "https://www.sa-safeme.com/terms",
    driverSignupBonus: "10",
    driverSupportURL: "https://www.sa-safeme.com/contactus",
    driverTermsURL: "https://www.sa-safeme.com/terms",
    riderPrivacyURL: "https://www.sa-safeme.com/terms",
    riderSupportURL: "https://www.sa-safeme.com/contactus",
    riderTermsURL: "https://www.sa-safeme.com/terms",
    sos: "10111"
  }
};
